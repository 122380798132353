import { graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/layout"
import Header from "../../components/section/header"
import Seo from "../../components/seo"
import Price from "../../utils/Price"

export default function Book({ data, location }) {
  return (
    <Layout>
      <Seo title="Book Online" pathname={location.pathname} />
      <Header title="Book Online" />
      <Booking booking={data.booking.nodes} />
    </Layout>
  )
}

const Booking = ({ booking }) => {
  return (
    <section className="py-6 max-w-7xl w-11/12 2xl:w-full mx-auto">
      <div className="flex flex-col lg:flex-row lg:justify-center gap-4">
        {booking.map((item, key) => {
          const book = item.frontmatter
          return (
            <div key={key} className="flex-col bg-primary lg:w-1/3 ">
              <div className="h-64">
                {book?.image?.childImageSharp ? (
                  <GatsbyImage
                    image={book.image.childImageSharp.gatsbyImageData}
                    alt={book.title || " "}
                    className="h-full w-full object-cover"
                  />
                ) : (
                  <img
                    src={book.image}
                    alt={book.title || " "}
                    className="h-full w-full object-cover"
                  />
                )}
              </div>
              <div className="p-2 flex flex-col gap-3 lg:h-[12rem]">
                <h3 className="text-xl font-serif text-secondary">
                  {book.title}
                </h3>
                <div className="flex-grow-0 h-full">
                  <p className="text-sm font-light text-secondary/90">
                    {book.description}
                  </p>
                </div>

                <div className="flex gap-2 items-center justify-between ">
                  <div className="flex flex-col gap-2">
                    <span className="text-lg font-serif">
                      {Price(book.price, "GBP")} / {Price(book.price_1, "USD")}{" "}
                      USD{" "}
                    </span>
                    <span>{book.duration} min session</span>
                  </div>
                  <button
                    onClick={() => navigate(`/book-online${item.fields.slug}`)}
                    className="px-4 py-3 bg-secondary text-primary text-xs font-light uppercase tracking-widest"
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export const query = graphql`
  query {
    booking: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "booking" } }
        frontmatter: { publish: { eq: true } }
      }
      sort: { order: ASC, fields: frontmatter___id }
    ) {
      nodes {
        frontmatter {
          title
          publish
          image
          description
          price
          price_1
          duration
        }
        fields {
          slug
        }
      }
    }
  }
`
